/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import HeroSingle from '../components/pages/HeroSingle';
import Footer from '../components/Footer';
import LineFull from '../images/linefull.jpg';
import SEO from '../components/seo';
import '../styles/app.scss';

const Calendar = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    const [limit, setLimit] = useState(3);

    function loadImages() {
        return post.acf.calendar_images
            .slice(0, limit)
            .map((month, i) => (
                <img
                    src={month.localFile.childImageSharp.fluid.src}
                    alt="calendar "
                    key={i}
                />
            ));
    }
    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[0].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />

            <section className="page-section centersec">
                <div className="container smallestwdt wrtscalendarwrap">
                    {loadImages()}
                </div>

                <div>
                    <img
                        className="purposeline"
                        src={LineFull}
                        alt="linefull"
                    />
                    <a
                        className="wrtsbtn yellowbtn pointer"
                        onClick={() => setLimit(limit + 3)}
                    >
                        LOAD MORE
                    </a>
                </div>
            </section>
            <Footer />
        </>
    );
};
export default Calendar;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            acf {
                calendar_images {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
